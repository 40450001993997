.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: 40px auto;
}

.flex-center {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.Link {
  color: #0071e3;
  font-size: 18px;
  text-decoration: none;
  font-family: inherit;
  margin-top: 16px;
  margin-bottom: 16px;
}

.Button {
  font-family: inherit;
  min-width: 28px;
  background-color: #0071e3;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  box-sizing: border-box;
  display: block;
  border-radius: 12px;
  padding: 12px 32px;
  font-size: 18px;
  border: none;
  font-weight: 700;
  box-shadow: 0 4px darkblue;
  margin: 20px 0;
}

.Button.secondary {
  color: #333;
  background-color: #fff;
  border: 2px solid #ccc;
  box-shadow: 0 4px #ccc;
}


.Button:not([disabled]):hover {
  background-color: #0077ed;
  outline: none;
}

.Button:not([disabled]):active {
  background-color: #0077ed;
  transform: translateY(4px);
  box-shadow: none;
}

.Button.secondary:not([disabled]):active, 
.Button.secondary:not([disabled]):hover {
  background-color: #fff;
}

.Button:disabled {
  opacity: 0.5;
}

.full-width {
  width: 100%;
  max-width: 100%;
}

.LoginForm {
  box-sizing: border-box;
  /* width: 90%;
  max-width: 500px; */
  flex: 1;
  border: 2px solid #333;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  margin: 16px;
}

.FormInput {
  box-sizing: border-box;
  font-family: inherit;
  font-size: 18px;
  padding: 12px 20px;
  border-radius: 12px;
  border: 2px solid #ccc;
  min-width: 200px;
  margin-top: 12px;
}

.FormInput:focus {
  border-color: #0077ed;
}

.centered-columns {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.border-top {
  border: 1px solid rgb(103, 103, 103);
  border-radius: 16px;
  padding: 16px 32px;
  margin-top: 16px;
}

.centered-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.calendar-month {
  padding: 16px;
  flex: 1;
  text-align: center;
}

.month-navigator {
  padding: 16px;
  color: #0071e3;
  font-size: 18px;
  border: 1px solid #0071e3;
  border-radius: 10px;
  appearance: none;
  background-color: #fff;
  font-family: inherit;
}

.month-link {
  color: #0071e3;
  text-decoration: none;
  font-family: inherit;
}